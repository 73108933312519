import { DEFAULT_GF_TIME_PERIOD_ID } from 'constants/strings';
import { SmartPersonaMeasurementInput } from 'types/graphql/generated';
import { createHook, createStore } from 'react-sweet-state';
import { hideIntercom, showIntercom } from 'utils/hideOrShowIntercom';
import { popUrlParam, pushUrlParam } from 'utils/smartPersonas/urlParameters';

// Helper to parse URL parameters
const getUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);

  // For timePeriod, just store/retrieve the ID
  const timePeriodId =
    urlParams.get('timePeriodId') || DEFAULT_GF_TIME_PERIOD_ID;
  const timePeriodCustomStart = urlParams.get('customStart') || null;
  const timePeriodCustomEnd = urlParams.get('customEnd') || null;
  const paymentChannel = urlParams.get('paymentChannel') || null;
  const demographicsStr = urlParams.get('demographics') || '';
  const demographics = demographicsStr.length ? demographicsStr.split(',') : [];

  const timePeriod =
    timePeriodId === 'custom'
      ? {
          custom: {
            start: Number(timePeriodCustomStart),
            end: Number(timePeriodCustomEnd),
          },
        }
      : { id: timePeriodId };

  return { timePeriod, paymentChannel, demographics };
};

const StoreGlobalState = createStore({
  initialState: (() => {
    const { timePeriod, paymentChannel, demographics } = getUrlParams();

    return {
      drawerContents: undefined,
      insightsFiltersTimePeriod: timePeriod,
      insightsFiltersPaymentChannel: paymentChannel,
      insightsFiltersDemographics: demographics,
      showDrawer: false,
      showNav: false,
      showToast: false,
      smartPersonaId: '',
      smartPersonasInputData: undefined as
        | SmartPersonaMeasurementInput
        | undefined,
      smartPersonaType: '',
      toastError: false,
      toastHeader: '',
      toastMsg: undefined as string | React.ReactNode,
    };
  })(),
  actions: {
    setPaymentChannel:
      (paymentChannel) =>
      ({ setState }) => {
        // Update global state
        setState({ insightsFiltersPaymentChannel: paymentChannel });
        // Update URL
        pushUrlParam('paymentChannel', paymentChannel);
      },
    setTimePeriod:
      (timePeriod) =>
      ({ setState }) => {
        // Update global state
        setState({ insightsFiltersTimePeriod: timePeriod });
        // Update URL with just the ID
        pushUrlParam('timePeriodId', timePeriod.id);
      },
    setInsightsFilters:
      (demographics, paymentChannel, timePeriod) =>
      ({ setState }) => {
        setState({
          insightsFiltersTimePeriod: timePeriod,
          insightsFiltersPaymentChannel: paymentChannel,
          insightsFiltersDemographics: demographics,
          showDrawer: false,
        });
        // Update URL
        pushUrlParam('timePeriodId', timePeriod.id);
        pushUrlParam('paymentChannel', paymentChannel || '');
        pushUrlParam('demographics', demographics.join(','));
      },
    resetInsightsFilters:
      () =>
      ({ setState }) => {
        setState({
          insightsFiltersTimePeriod: { id: DEFAULT_GF_TIME_PERIOD_ID },
          insightsFiltersPaymentChannel: null,
          insightsFiltersDemographics: [],
        });
        // Reset URL
        popUrlParam('timePeriodId');
        popUrlParam('paymentChannel');
        popUrlParam('demographics');
      },
    showDrawer:
      () =>
      ({ setState }) => {
        hideIntercom();
        pushUrlParam('personas', 'open');
        setState({ showDrawer: true });
      },
    hideDrawer:
      () =>
      ({ setState }) => {
        showIntercom();
        popUrlParam('personas');
        popUrlParam('personaType');
        popUrlParam('tab');
        setState({
          showDrawer: false,
          drawerContents: undefined,
        });
      },
    showNav:
      () =>
      ({ setState }) => {
        setState({ showNav: true });
      },
    hideNav:
      () =>
      ({ setState }) => {
        setState({ showNav: false });
      },
    setDrawerContents:
      (component) =>
      ({ setState }) => {
        setState({
          showDrawer: true,
          drawerContents: component,
        });
      },
    setSmartPersonaId:
      (id, type) =>
      ({ setState }) => {
        pushUrlParam('personas', id);
        pushUrlParam('personaType', type);
        setState({
          showDrawer: true,
          smartPersonaType: type,
          smartPersonaId: id,
        });
      },
    smartPersonaInputData:
      (data: SmartPersonaMeasurementInput | null, type) =>
      ({ setState }) => {
        setState({
          smartPersonasInputData: data ?? undefined,
          smartPersonaType: type,
        });
      },
    showToast:
      (header, msg) =>
      ({ setState }) => {
        setState({
          toastHeader: header,
          toastMsg: msg,
          showToast: true,
          toastError: false,
        });
      },
    showToastError:
      (header, msg) =>
      ({ setState }) => {
        setState({
          toastError: true,
          toastHeader: header,
          toastMsg: msg,
          showToast: true,
        });
      },
    hideToast:
      () =>
      ({ setState }) => {
        setState({
          showToast: false,
          toastError: false,
        });
      },
  },
});

export const useGlobalState = createHook(StoreGlobalState);
