import Drawer from '@mui/material/Drawer';
import styled from 'styled-components';
import { Snackbar, theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  ${theme.typography.bodyLG};
  margin: 0 auto;
  min-height: 100vh;
  width: 100%;

  .MuiAutocomplete-root.MuiAutocomplete-root {
    .MuiPaper-root {
      box-shadow: ${theme.mixins.shadowLight};
    }

    .MuiInputBase-root {
      padding-inline: ${theme.spacing.md};
    }

    .MuiInputBase-adornedStart {
      gap: ${theme.spacing.sm};
    }

    .MuiInputBase-input {
      padding-block: 0;
      padding-inline-end: calc((${theme.spacing.md} * 2) + ${theme.icons.md});
      position: relative;
      z-index: 1;
    }

    svg {
      height: ${theme.icons.md};
      width: ${theme.icons.md};
    }

    .MuiAutocomplete-endAdornment {
      right: ${theme.spacing.md};
      z-index: 0;
    }

    .MuiAutocomplete-popupIndicatorOpen {
      transform: none;
    }

    .MuiAutocomplete-listbox {
      padding-block: 0;
    }

    .MuiAutocomplete-option.Mui-focused {
      background: ${theme.colors.backgroundBrandLightest};
    }
    .MuiAutocomplete-option[aria-selected='true'] {
      background: ${theme.colors.backgroundBrandLight};
    }
  }
`;

export const GlobalGrid = styled.div`
  display: grid;
  grid-template-areas:
    'header'
    'theRest';
  grid-template-columns: 100%;
  grid-template-rows: auto;

  @media ${theme.mq.tabletPortraitUp} {
    grid-template-areas:
      'header header'
      'nav theRest';
    grid-template-columns: 200px auto;
    grid-template-rows: 64px auto;
  }
`;

export const Header = styled.header`
  grid-area: header;
  position: fixed;
  top: 0;
  height: 64px;
  width: 100%;
  z-index: 2;
`;

export const Nav = styled.nav`
  grid-area: nav;
  height: 0;
  position: fixed;
  top: 64px;
  width: 100%;
  z-index: 3;

  @media ${theme.mq.tabletPortraitUp} {
    height: auto;
    width: 200px;
    z-index: auto;
  }
`;

export const ContentNew = styled.div`
  min-height: calc(100vh - 64px);
  grid-area: theRest;
  padding: 64px ${theme.spacing.md} ${theme.spacing.lg};

  @media ${theme.mq.tabletPortraitUp} {
    padding: 0 ${theme.spacing.xl} ${theme.spacing.lg};
  }
`;

export const Content = styled.div`
  min-height: 100vh;
  padding: 0 ${theme.spacing.md} ${theme.spacing.lg};
  width: 100%;

  @media ${theme.mq.tabletPortraitUp} {
    margin-left: 200px;
    padding-inline: ${theme.spacing.xl};
    width: calc(100% - 260px); // account for nav bar width
  }
`;

export const DrawerWrapper = styled(Drawer)`
  && {
    .MuiDrawer-paper {
      background: ${theme.colors.backgroundBrandLightest};
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;

      @media ${theme.mq.tabletPortraitUp} {
        width: 680px;
      }
    }
  }
`;

export const Toast = styled(Snackbar)<{ error: boolean }>`
  && {
    bottom: 20px;
    right: 92px;

    .MuiPaper-root {
      background: ${({ error }) => {
        return error ? theme.colors.errorForeground : '#18171c';
      }};
      max-width: 400px;
    }
  }
`;

export const ToastHeader = styled.p`
  ${theme.typography.headingXS};
  margin-block: 0 ${theme.spacing.xxs} !important;
`;
